<template>
  <div>
    <v-container grid-list-md class="single-advisor">
      <template v-if="advisor">
        <v-card class="pa-3">
          <v-card-text>
              <v-layout wrap row>
                <v-flex xs12 md4 justify-center>
                  <v-flex justify-center>
                    Advisor Photo
                    <v-img
                      :src="advisor.photo ? advisor.photo : defaultAdvisorImage"
                    ></v-img>
                    <v-file-input
                      accept="image/jpeg"
                      outlined
                      dense
                      hide-input
                      prepend-icon="mdi-camera"
                      v-model="img"
                      ref="file"
                      class="v-file"
                    ></v-file-input>
                    <v-flex xs12 class="d-flex" justify-center>
                      <v-btn class="mr-2" :href="advisorImageURL" target="_blank">Image URL</v-btn>
                      <v-btn @click="updateImage">Update</v-btn>
                    </v-flex>
                  </v-flex>
                  <v-flex justify-center>
                    Acoustic Photo
                    <v-img
                      :src="advisor.acousticPhoto ? advisor.acousticPhoto : defaultAdvisorImage"
                    ></v-img>
                    <v-file-input
                      accept="image/jpeg"
                      outlined
                      dense
                      hide-input
                      prepend-icon="mdi-camera"
                      v-model="img2"
                      ref="file2"
                      class="v-file"
                    ></v-file-input>
                    <v-flex xs12 class="d-flex" justify-center>
                      <v-btn class="mr-2" :href="advisorImageURL2" target="_blank">Image URL</v-btn>
                      <v-btn @click="updateImage2">Update</v-btn>
                    </v-flex>
                  </v-flex>
                </v-flex>
                
                <v-flex xs12 md8 class="pl-4">
                  <v-form
                    ref="form"
                    v-model="valid"
                  >
                    <v-layout wrap row>
                      <v-flex xs6>
                        <v-text-field
                          :color="appButtonColor"
                          hide-details
                          v-model="advisor.id"
                          label="ID"
                          readonly
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs6>
                        <v-text-field
                          :color="appButtonColor"
                          hide-details
                          v-model="advisor.createdAt"
                          label="Created"
                          readonly
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs6>
                        <v-text-field
                          :color="appButtonColor"
                          hide-details
                          v-model="advisor.first"
                          label="First Name"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs6>
                        <v-text-field
                          :color="appButtonColor"
                          hide-details
                          v-model="advisor.last"
                          label="Last Name"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs6>
                        <v-text-field
                          :color="appButtonColor"
                          hide-details
                          v-model="advisor.email"
                          label="Email"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs6>
                        <v-text-field
                          :color="appButtonColor"
                          hide-details
                          v-model="advisor.phone"
                          label="Phone"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs6>
                        <v-text-field
                          :color="appButtonColor"
                          hide-details
                          v-model="advisor.designations"
                          label="Designation(s)"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs6>
                        <v-text-field
                          :color="appButtonColor"
                          hide-details
                          v-model="advisor.jobTitle"
                          label="Job Title"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs6>
                        <v-text-field
                          :color="appButtonColor"
                          hide-details
                          v-model="advisor.registeredAdvisorTitle"
                          label="Registered Advisor Title"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs6>
                        <v-switch
                          :color="appButtonColor"
                          hide-details
                          v-model="advisor.brandApprovedPhoto"
                          label="Registered Advisor Title"
                        ></v-switch>
                      </v-flex>
                    </v-layout>
                  </v-form>
                </v-flex>
              </v-layout>
          </v-card-text>
          <v-card-actions class="d-flex justify-end">
            <v-btn to="/">Back</v-btn>
            <v-btn :disabled="!userUpdated" @click="updateAdvisor" :loading="saveLoading">Save</v-btn>
          </v-card-actions>
        </v-card>
        <!-- <v-card class="mt-4">
          <v-card-title>Clients</v-card-title>
          <v-card-text>
            <v-data-table
              :headers="clientHeaders"
              :items="clients"
              class="elevation-1 table-striped my-3"
              item-key="a360id"
              sort-desc
            />
          </v-card-text>
        </v-card> -->
      </template>
      <v-progress-linear
      v-else
      indeterminate
      :color="appButtonColor"
    ></v-progress-linear>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import defaultAdvisorImage from "@/assets/imgs/default.jpeg"
export default {
  name: "Advisor",
  data() {
    return {
      advisorCopy: {},
      setImage: false,
      valid: true,
      img: null,
      img2: null,
      saveLoading: false,
      changedImage: false,
      advisorImageURL: "",
      advisorImageURL2: "",
      previousImage: "",
      defaultAdvisorImage,
      clients: [],
      clientHeaders: [
        { text: "A360ID", sortable: true, value: "a360id" },
        { text: "Account Type", sortable: false, value: "accountType" }
      ],
      rules: {
        required: v => !!v || "Required",
        phoneRequired: v => !!v || "Phone is required",
        min: v => v.length >= 8 || v.length === 0 || "Min 8 characters",
        upper: v =>
          /[A-Z]/.test(v) || v.length === 0 || "Must include uppercase letter",
        number: v => /[0-9]/.test(v) || v.length === 0 || "Must include number",
        email: v =>
          /.+@.+/.test(v) || v.length === 0 || "Invalid email address",
        phone: v => v.length === 10 || v.length === 0 || "Phone is invalid",
        match: v =>
          this.userDetails.password === this.userDetails.password2 || v.length === 0 ||
          "Passwords don't match",
        pass: v => this.userDetails.password === this.userDetails.password2 || "Please enter password again"
      }
    }
  },
  async created() {
    await this.$store.dispatch("user/getAdvisorInfo", this.$route.params.id)
    if (this.advisor) {
      this.advisor.createdAt = this.formatDate(this.advisor.createdAt)
      this.advisorCopy = JSON.parse(JSON.stringify(this.advisor))
      if (this.advisor.photo) {
        await fetch(`${process.env.VUE_APP_DOMAIN}/images/${this.advisor.id}.jpg`, {
          mode: 'no-cors',
          cache: 'no-cache'
        })
        this.advisorImageURL = this.advisor.photo
      }
      if (this.advisor.users) {
        this.clients = this.advisor.users.items
      }
    }
  },
  watch: {
    img(val) {
      if (val) {
        this.previousImage = this.advisor.photo
        this.updateCurrent(val, 'photo')
        this.changedImage = true
        this.updateAdvisorImage(val, 'photo')
      }
    },
    img2(val) {
      if (val) {
        this.previousImage = this.advisor.acousticPhoto
        this.updateCurrent(val, 'acousticPhoto')
        this.changedImage = true
        this.updateAdvisorImage(val, 'acousticPhoto')
      }
    }
  },
  computed: {
    ...mapState("user", [
      "advisor"
    ]),
    ...mapGetters("user", ["userGroup"]),
    // advisorPhoto() {
    //   if (!this.setImage) {
    //     this.setImage = true
    //     if (this.advisor.photo) {
    //       return this.advisor.photo
    //     }
    //     else return require("@/assets/imgs/default.jpeg")
    //   }
    // },
    superOrSysAdmin() {
      if (this.userGroup === "Super" || this.userGroup === "SystemAdmin") {
        return true;
      } else return false;
    },
    snBypass() {
      if (this.userInfo.snMFABypass === null)
        this.userInfo.snMFABypass = "false";
      return this.capitalize(JSON.parse(this.userInfo.snMFABypass).toString());
    },
    appButtonColor() {
      return this.mersBranded ? "#33715A" : "#0B2A4A";
    },
    userTerms() {
      return new Date(this.userInfo.terms).toLocaleString("en-US");
    },
    createdDate() {
      return new Date(this.userInfo.created).toLocaleString("en-US");
    },
    indexedAccounts() {
      return this.accounts.map((item, index) => ({
        id: index,
        ...item,
      }));
    },
    changedEmail() {
      if (this.clonedItem.email !== this.editedItem.email) return true;
      else return false;
    },
    changedEmailVerified() {
      if (this.clonedItem.emailVerified !== this.editedItem.emailVerified)
        return true;
      else return false;
    },
    newSponsorFlags() {
      return {
        Name: "custom:userFlags",
        Value: JSON.stringify(this.newSponsor.flags),
      };
    },
    userUpdated() {
      if (this.changedImage) return true
      else if (JSON.stringify(this.advisor) !== JSON.stringify(this.advisorCopy)) return true
      else return false
    }
  },
  methods: {
    cancelUpdate() {
      this.advisor.photo = this.previousImage
      this.changedImage = false
    },

    /**
     * 
     */
    updateCurrent(val, target) {
      let that = this
      const reader = new FileReader()
      reader.addEventListener("load", function () {
        that.advisor[target] = reader.result
      }, false)
      reader.readAsDataURL(val)
    },

    /**
     * 
     */
    async updateAdvisorImage(img, type) {
      this.saveLoading = true
      let advisor = JSON.parse(JSON.stringify(this.advisor))

      let fileName = `${advisor.id}.jpg`
      if (type === 'acousticPhoto') {
        fileName = `${advisor.id}-acoustic.jpg`
      }

      let photo = await this.$store.dispatch("user/saveImage", {
        photo: img,
        fileName
      })

      if (!advisor[type]) {
        let updateBody = {
          id: advisor.id
        }
        updateBody[type] = `${process.env.VUE_APP_DOMAIN}/images/${fileName}`
        await this.$store.dispatch("user/updateAdvisorImage", updateBody)
      }
      this.saveLoading = false
      if (photo) {
        this.displayNotification("success", "Advisor Updated")
        this.advisorCopy = JSON.parse(JSON.stringify(this.advisor))
        this.previousImage = ""
        this.changedImage = false
        this.img = null
      }
    },

    /**
     * 
     */
    updateImage() {
      this.$refs.file.$refs.input.click()
    },
    updateImage2() {
      this.$refs.file2.$refs.input.click()
    },
    async updateAdvisor() {
      if (!this.$refs.form.validate()) return
      let final = JSON.parse(JSON.stringify(this.advisor))
      if (final.first) {
        final.first = this.capitalize(this.advisor.first)
      }
      if (final.last) {
        final.last = this.capitalize(this.advisor.last)
      }
      if (this.changedImage) {
        final.photo = this.img
        final.updatedPhoto = this.advisor.photo
      }
      else delete final.photo
      // if (this.advisor.phone) {
      //   // final.phone = this.formatPhone(this.advisorPhone)
      //   final.phone = 
      // }
      this.saveLoading = true
      let updatedAdvisor = await this.$store.dispatch("user/updateSingleAdvisor", final)
      this.saveLoading = false
      if (updatedAdvisor) {
        this.displayNotification("success", "Advisor Updated")
        this.advisorCopy = JSON.parse(JSON.stringify(this.advisor))
        this.previousImage = ""
        this.changedImage = false
        this.img = null
      }
      else {

      }
    },
    iconTypeCheck(type) {
      let check1 = this.phonePrimary === this.currentPhones[type].type
      let check2 = this.copyCurrentPhones[type].number
      if (check1 || !check2) return false
      else return true
    },
    setupPhones() {
      let allPhones = JSON.parse(JSON.stringify(this.userInfo.phones))
      this.currentPhones.home = this.formatPhone(allPhones, "home")
      this.currentPhones.mobile = this.formatPhone(allPhones, "mobile")
      this.currentPhones.work = this.formatPhone(allPhones, "work")
      this.copyCurrentPhones = JSON.parse(JSON.stringify(this.currentPhones))
      this.copy = true
    },
    checkFlagsForSN() {
      if (
        this.userFlags.isAdmin ||
        this.userFlags.OCEAccess ||
        this.userFlags.CFUAccess
      )
        this.snPhoneId = this.$route.params.id;
      else this.snPhoneId = this.userInfo.alerus360id;
    },
    createEmptyPhone(type) {
      let phone = JSON.parse(JSON.stringify(this.emptyPhone))
      phone.type = type
      return phone
    },
    async removePhone(type) {
      await this.updatePhone(type, true)
    },
    // async removePhone(phoneId) {
    //   let res = await this.$store.dispatch("user/removeSecureNowPhone", {
    //     id: phoneId,
    //     user: this.snPhoneId,
    //   });
    //   if (res) {
    //     this.displayNotification("success", "phone");
    //   } else {
    //     this.displayNotification("error", "phone");
    //   }
    // },
    setUserMFA(info) {
      let mfa;
      let { snMFABypass, awsMFA, defaultMfa } = info;
      if (snMFABypass === "true") {
        mfa = "off";
      } 
      else if (defaultMfa) {
        if (defaultMfa === "sms" || defaultMfa === "voice") mfa = "securenow"
        else if (defaultMfa === "totp") mfa = "aws"
      }
      else if (snMFABypass === "false") {
        mfa = "securenow";
      }
      this.mfa = mfa;
      this.currentMFA = mfa;
    },
    async setMFA(item) {
      let set = await this.$store.dispatch("accounts/setUserMFA", {
        username: this.userInfo.username,
        mfa: this.mfa,
        a360id: this.userInfo.alerus360id
      });
      if (set) {
        this.currentMFA = item;
        this.displayNotification("success", "User MFA");
      } else {
        this.mfa = this.currentMFA;
        this.displayNotification("error", "User MFA");
      }
    },
    capitalize(item) {
      return item.slice(0, 1).toUpperCase() + item.slice(1);
    },
    appName(app) {
      return this.mersBranded && app === "MyAlerus"
        ? "Login"
        : this.mersBranded && app === "CSC"
        ? "CSR"
        : app;
    },
    async bypassMFA() {
      if (!this.superOrSysAdmin) return;
      let bypass = "True";
      if (this.snBypass === "True") bypass = "False";
      let data = await this.$store.dispatch("accounts/snBypassMFA", {
        username: this.userInfo.username,
        bypass: bypass.toLowerCase(),
      });
      if (data) this.userInfo.snMFABypass = bypass.toLowerCase();
    },
    formatPhone(phones, type) {
      let phone = phones.find(item => item.type === type) ? phones.find(item => item.type === type) : this.createEmptyPhone(type)
      let phoneNumber = phone.number.slice(phone.countryCode ? phone.countryCode.length + 1 : 2)
      phone.number = phoneNumber
      if (!phone.countryCode) phone.countryCode = "1"
      return phone
    },
    async updatePrimary() {
      if (!this.copyCurrentPhones[this.userInfo.primary].number || (this.phonePrimary === this.userInfo.primary)) {
        return
      }
      this.primaryLoading = true
      let phone = JSON.parse(JSON.stringify(this.currentPhones[this.userInfo.primary]))
      phone.number = `+${phone.countryCode}${phone.number}`
      let prevPhone = this.copyCurrentPhones[this.phonePrimary]
      let prev = `+${prevPhone.countryCode}${prevPhone.number}`
      await this.$store.dispatch("accounts/updatePhone", {
        username: this.userInfo.username,
        a360id: this.userInfo.alerus360id,
        phone,
        primary: true,
        prev,
        primaryUpdate: true,
        currType: this.capitalize(this.phonePrimary),
        newType: this.capitalize(this.userInfo.primary)
      })
      this.phonePrimary = this.userInfo.primary
      this.primaryLoading = false
    },
    checkDisabled(type) {
      let check1 = this.currentPhones[type].countryCode === "1" && this.currentPhones[type].number.length < 10
      let check2 = this.copyCurrentPhones[type].number === this.currentPhones[type].number
      if (check1 || check2) return true
      else return false
    },
    async updatePhone(type, remove = false) {
      if (this.checkDisabled(type) && !remove) return
      let phone;
      let loadType = `${type}Loading`
      this[loadType] = true
      if (remove) {
        phone = this.createEmptyPhone(type)
      }
      else {
        phone = JSON.parse(JSON.stringify(this.currentPhones[type]))
        phone.number = `+${phone.countryCode}${phone.number}`
      }
      let prevPhone = this.copyCurrentPhones[type]
      let prev = `+${prevPhone.countryCode}${prevPhone.number}`
      let res = await this.$store.dispatch("accounts/updatePhone", {
        username: this.userInfo.username,
        a360id: this.userInfo.alerus360id,
        phone,
        primary: type === this.phonePrimary ? true : false,
        prev: prevPhone.number ? prev : "NA",
        primaryUpdate: false
      })

      // Display notification
      if (res) {
        if (!remove) {
          this.copyCurrentPhones[type] = JSON.parse(JSON.stringify(this.currentPhones[type]))
        }
        else {
          this.currentPhones[type] = this.createEmptyPhone(type)
          this.copyCurrentPhones[type] = JSON.parse(JSON.stringify(this.currentPhones[type]))
        }
        this.displayNotification("success", `${this.capitalize(type)} phone`)
      }
      else this.displayNotification("error", type)
      this[loadType] = false
    },
    async generatePasscode() {
      if (!this.adminOrSuper) return;
      let code = Math.floor(100000 + Math.random() * 900000);
      let result = await this.$store.dispatch("accounts/sendCode", {
        message: code.toString(),
        number: this.clonedItem.phone,
      });
      if (result) {
        this.passCode = code;
      } else {
        this.passCode = "Error sending code";
      }
    },
    showActionDetails(item) {
      this.actionItem = item;
      this.actionDialog = true;
    },
    genPass() {
      if (!this.adminOrSuper) return;
      this.newSponsor.password = this.$generate({
        length: 12,
        numbers: true,
        symbols: true,
        strict: true,
        exclude: "\"`'",
      });
    },
    displayNotification(type, notification) {
      if (type === "success") {
        this.$snotify[type](`${notification}`);
      } else if (type === "number") {
        this.$snotify["error"](`${notification}`, "", {
          timeout: 0,
        });
      } else {
        this.$snotify[type](`Error updating ${notification}`, "", {
          timeout: 0,
        });
      }
    },
    async createUserLog(username, log, app) {
      await this.$store.dispatch("accounts/createLog", {
        username: username,
        log: log,
        app: app,
      });
    },
    async changeUserAccount(status) {
      if (!this.adminOrSuper) return;
      if (!status) {
        let newStatus = await this.$store.dispatch(
          "accounts/disableUserAccount",
          this.userInfo.username
        );
        if (newStatus) {
          this.userInfo.enabled = false;
          // this.$store.dispatch(
          //   "accounts/getUserAuthEvents",
          //   this.$route.params.id
          // );
        } else this.userInfo.enabled = true;
      } else {
        let newStatus = await this.$store.dispatch(
          "accounts/enableUserAccount",
          this.userInfo.username
        );
        if (newStatus) {
          // this.$store.dispatch(
          //   "accounts/getUserAuthEvents",
          //   this.$route.params.id
          // );
          this.userInfo.enabled = true;
        } else this.userInfo.enabled = false;
      }
    },
    async resetPass() {
      if (!this.adminOrSuper) return;
      let newPass = await this.$store.dispatch(
        "accounts/resetUserPassword",
        this.userInfo.username
      );
      if (newPass) {
        this.userInfo.userStatus = "RESET_REQUIRED";
        await this.createUserLog(
          this.userInfo.username,
          "ResetPassword",
          "CSC"
        );
        // this.$store.dispatch(
        //   "accounts/getUserAuthEvents",
        //   this.$route.params.id
        // );
      } else console.log("failed to reset password");
    },
    async addSponsor() {
      if (!this.newSponsorObject.username || !this.newSponsorObject.password)
        return;
      this.newSponsor.atts.email = this.newSponsor.atts.email.toLowerCase();

      let sponsor = await this.$store.dispatch(
        "accounts/newSponsor",
        this.newSponsorObject
      );
      if (sponsor) {
        this.sponsor = false;
        this.newSponsor = this.sponsorCopy;
      }
    },
    async changePassword() {
      this.passwordInfo = "";
      let updated = await this.$store.dispatch("accounts/updateUserPassword", {
        username: this.userInfo.username,
        password: this.newPassword,
      });
      if (updated) {
        this.infoColor = "green";
        this.newPassword = "";
        this.passwordInfo = "Password updated successfully";
        await new Promise((resolve) => setTimeout(resolve, 4000));
        this.passwordInfo = "";
      } else {
        this.infoColor = "red";
        this.passwordInfo = "Error updating password";
      }
    },
    formatDate(date) {
      let year = date.slice(0, 4);
      let month = date.slice(5, 7);
      let day = date.slice(8, 10);
      return `${month}/${day}/${year}`;
    },
    resetAccounts() {
      this.$store.commit("accounts/resetUsers", this.allAccounts);
      this.errors.clear();
      this.dialog = false;
      this.emailError = "";
      this.clonedItem = {};
      this.editedItem = {};
    },
    setFlags(item) {
      let flags = {};
      if (item) {
        try {
          flags = JSON.parse(item);
        } catch (error) {}
      }
      this.userFlags = {
        isAdmin: flags.isAdmin !== undefined ? flags.isAdmin : false,
        isAlerus: flags.isAlerus !== undefined ? flags.isAlerus : false,
        isFCMM: flags.isFCMM !== undefined ? flags.isFCMM : false,
        isICapital: flags.isICapital !== undefined ? flags.isICapital : false,
        isKeating: flags.isKeating !== undefined ? flags.isKeating : false,
        isMERS: flags.isMERS !== undefined ? flags.isMERS : false,
        isEnvestnet:
          flags.isEnvestnet !== undefined ? flags.isEnvestnet : false,
        OCEAccess: flags.OCEAccess !== undefined ? flags.OCEAccess : false,
        CFUAccess: flags.CFUAccess !== undefined ? flags.CFUAccess : false,
        partAccess: flags.partAccess !== undefined ? flags.partAccess : false,
        isWexFLXAdmin: flags.isWexFLXAdmin !== undefined ? flags.isWexFLXAdmin : false,
        isMersContact: flags.isMersContact !== undefined ? flags.isMersContact : false
      };
    },
    getItem(item) {
      this.$store.dispatch("accounts/getUserAuthEvents", item.username);
      this.setFlags(item);
      this.clonedItem = { ...item };
      this.allAccounts = this._.cloneDeep(this.accounts);
      this.editedItem = item;
      this.dialog = true;
    },
    findDifference() {
      let changes = Object.keys(this.clonedItem).filter(
        (prop) => this.clonedItem[prop] !== this.editedItem[prop]
      );
      return changes.map((item) => {
        return {
          Name: this.userAtts[item],
          Value: this.editedItem[item],
        };
      });
    },
    findKeys() {
      let newObject = {};
      let changes = Object.keys(this.clonedItem)
        .filter((prop) => this.clonedItem[prop] !== this.editedItem[prop])
        .forEach((item) => {
          newObject[item] = this.editedItem[item];
        });
      return newObject;
    },
    checkEmail() {
      if (this.userEmails.includes(this.editedItem.email)) return false;
      else return true;
    },
    async updateUser(name, value, type, notification, clonedName) {
      if (!this.adminOrSuper) return;
      if (!value) {
        return;
      } else if (!this.userInfo.country) {
      } else if (this.clonedItem[clonedName] === value) {
        return;
      }

      let finalAtts = [];

      if (name === "email") {
        this.emailLoading = true
        finalAtts = [
          {
            Name: name,
            Value: value,
          },
          {
            Name: "email_verified",
            Value: "true",
          },
        ];
      } else if (name === "phone_number") {
        this.phoneLoading = true
        finalAtts = [
          {
            Name: name,
            Value: value,
          },
          {
            Name: "custom:countryCode",
            Value: this.country.replace("+", ""),
          },
          {
            Name: "userEmail",
            Value: this.userInfo.email,
          },
        ];
      } else {
        finalAtts = [
          {
            Name: name,
            Value: value,
          },
        ];
      }

      // Add current value for change
      finalAtts.push({
        Name: name,
        Value: this.clonedItem[clonedName] ? this.clonedItem[clonedName] : "",
      });

      let updated = await this.$store.dispatch("accounts/updateUserAtts", {
        userAtts: {
          username: this.userInfo.username,
          newAtts: finalAtts,
          a360id: this.userInfo.alerus360id
        },
      });
      if (updated) {
        this.$snotify.clear();
        this.displayNotification("success", notification);
        this.clonedItem[clonedName] = value;
        // this.$store.dispatch(
        //   "accounts/getUserAuthEvents",
        //   this.$route.params.id
        // );
        if (name === "email") {
          this.emailLoading = false
          this.userInfo.emailVerified = true;
        } else if (name === "phone_number") {
          this.phoneLoading = false
          this.userInfo.country = this.country.replace("+", "");
          // insert phone into Securenow
          await this.$store.dispatch("user/addSecureNowPhone", {
            full: finalAtts[0].Value.slice(1),
            country: finalAtts[1].Value,
            user: this.snPhoneId,
          });
        }
        this[type] = true;
        await new Promise((resolve) => setTimeout(resolve, 4000));
        this[type] = false;
      } else {
        this.phoneLoading = false
        this.emailLoading = false
        this.displayNotification("error", notification);
        this.userInfo[type] === this.clonedItem[type];
        if (name === "phone_number") {
          this.splitPhone();
        }
      }
    },
    async getAllEndpoints() {
      await Promise.all([
        this.getAccount(),
        this.getCallToAction(),
        this.getActionTags(),
        this.getFullAccount(),
      ]);
      this.dashboardReady = true;
    },
    async accountAuth() {
      let { data } = await $authUser.get(`/accountAuth`, {
        headers: {
          Authorization: `Bearer ${this.user.signInUserSession.idToken.jwtToken}`,
        },
      });
      this.authToken = data;
    },
    async getAccount() {
      this.accountLoading = true;
      let { data: account } = await $authUser.get(`/accounts`, {
        headers: {
          Authorization: `Bearer ${this.authToken}`,
        },
      });
      this.account = account;
      this.accountLoading = false;
    },
    async getCallToAction() {
      this.actionLoading = true;
      let { data: actions } = await $authUser.get(`/callToAction`, {
        headers: {
          Authorization: `Bearer ${this.authToken}`,
        },
      });
      this.actions = actions;
      this.actionLoading = false;
    },
    async getActionTags(userId = 444455555, accountId = "00001555555") {
      this.actionTagsLoading = true;
      let { data: actionTags } = await $authUser.get(
        `/accounts/${accountId}/actionTags`,
        {
          headers: {
            Authorization: `Bearer ${this.authToken}`,
          },
        }
      );
      this.actionTags = actionTags;
      this.actionTagsLoading = false;
    },
    splitPhone() {
      if (this.userInfo.country) {
        this.country = `+${this.userInfo.country}`;
        this.phone = this.userInfo.phone.replace(this.country, "");
      } else if (
        this.userInfo.phone.length === 12 &&
        this.userInfo.phone.substring(0, 2) === "+1"
      ) {
        this.country = this.userInfo.phone.substring(0, 2);
        this.phone = this.userInfo.phone.replace(this.country, "");
      } else {
        this.phone = this.userInfo.phone.replace("+", "");
        this.displayNotification(
          "number",
          "Please update country code and phone number"
        );
      }
    },
    async getFullAccount() {
      this.fullAccountLoading = true;
      let { data: full } = await $authUser.get(
        `https://api.alerus.com/accounts/00001555555?userHandle=444455555&participantId="partipandID"&planNumber=000015`,
        {
          headers: {
            Authorization: `Bearer ${this.authToken}`,
          },
        }
      );
      this.fullAccount = full;
      this.fullAccountLoading = false;
    },
    async branchLookup(branchZip) {
      let { data: branch } = await $authUser.get(
        `/branchCodeLookup?zipCode=${branchZip}`
      );
      this.branchInfo = branch;
    },
    async getSystem(system = this.system) {
      this.newUrl = true;
      let { data: authSystem } = await $authUser.get(
        `/getSystemLink?system=${system}`,
        {
          headers: {
            Authorization: `Bearer ${this.user.signInUserSession.idToken.jwtToken}`,
          },
        }
      );
      this.authUrl = `https://applanding.alerus.com/?auth=${authSystem}`;
      this.newUrl = false;
    },
  },
  beforeDestroy() {
    this.$store.commit("user/emptyAdvisor")
  }
};
</script>

<style lang="scss">
.single-advisor {
  .v-file {
    display: none;
  }
}
.v-radio {
  .v-input--selection-controls__input {
    margin-right: 2px;
  }
}
.snotifyToast__title {
  font-size: 1.25rem;
}
.events {
  tr.row {
    cursor: pointer;
  }
}
.mers .headline {
  font-family: "Ubuntu" !important;
}
.mfa-label {
  label {
    font-size: 12px;
  }
}
.alt-phone {
  font-size: 16px;
}
// .v-progress-linear{
//   width: 50%;
//   margin: 2rem auto;
// }
.mobile-order {
  @media (max-width: 959px) {
    .md6 {
      order: 1;
    }
  }
}
</style>